const localKey = 'kmks_username'

const  getUserName = () =>{
    return localStorage.getItem(localKey)
}

const setUserName =(name) =>{
    return localStorage.setItem(localKey , name)
}

const  clearLocalStorage =()=> {
    return localStorage.clear()
}

// 判断移动端设备
const isMobile = () => {
    let flag =  navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
   
    return flag
    
}


const GenerateUUID = () =>{
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    )
  }

export default {
    getUserName,
    setUserName,
    clearLocalStorage,
    isMobile,
    GenerateUUID
}
 