// 创建文件en/index.js
const en = {
    system: "AI digital Man AIGC full stack platform",
    theme: "Video 2 Video",
    firstIndex: "Index",
    topMenu:{
        menu01: "product",
        menu02: "introduction",
        menu03: "price"
    },
    login: "login",
    register:"register",
    siderMenu: {
        Video2Video: "Video2Video",
        AIWritting: "AI Writting",
        AIvoice: "AI Voice",
        AICharacter:"AI Character",
        VideoMake:"Video2Video",
        Customized:"Customized",
        AIAvatar: "AI Avatar"
    },    
    full: "full-screen display",
    account: "myAccount",
    invoice: "invoice",
    reconciliation: "Statement",
    record: "recording",
    report: "report",
    setting: "Settings",
  
    tips: "{name} Username and password are filled in casually {cont}",
    administrator: "administrator",
    placeUser: "please enter user name",
    palcePass: "Please enter your password",
    palceCode: "please enter verification code",
    accounts: "accounts",
    password: "password",
    code: "Verification code",
    footer: {
        CompanyProfile:"Company Profile",
        introduction: "Company Introduction",
        UserAgreement: "User Agreement",
        Privacy:"Privacy Agreement",
        Aboutus:"About Us",
        ContactUs:"Contact Us",
        CustomerService:"Customer Service",
        TechnicalSupport:"Technical Support"
    }
}
export default en
