import Vue from "vue";
import Router from "vue-router";
import Layout from "../layouts/LayoutsVue.vue";

Vue.use(Router);

// 重写 push replace方法
const originalPush = Router.prototype.push;

let originReplace = Router.prototype.replace;

//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Router.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch((err) => err);
};

const traditionalRoutes = [
  // {
    // path: "/",
    // redirect: "/system", // 重定向到 /tool
  // },
  // 登录
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login/index"),
    meta: { title: "登录", icon: "table" },
  },
  //注册
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Register/index.vue"),
    meta: { title: "注册", icon: "table" },
  },

  {
    path: "/password/find",
    name: "passwordfind",
    component: () => import("@/views/Password/find.vue"),
    meta: { title: "找回密码", icon: "table" },
  },

 
 
 
 
 
];

// 定义工具网站系统路由

// {
  // path: '/',
  // component: Layout,
  // children: [
    // {
      // path: "/",
      // name: "Pipleline",
      // component: () => import("@/views/Tool/pipeLine.vue"),
      // meta: { title: "工具网站", icon: "documentation" },
    // },
  // ]
  // 
// },

const ToolSystemRoutes = [
  {
    path: "/",
    component: Layout,
    // redirect: "/system/pipleline",
    name: "Example",
    // meta: { title: "Example", icon: "el-icon-s-help" },
    children: [
      {
        path: "/",
        name: "Pipleline",
        component: () => import("@/views/Tool/pipeLine.vue"),
        meta: { title: "工具网站", icon: "table" },
      },
      {
        path: "/price",
        name: "Price",
        component: () => import("@/views/Tool/PriceVue.vue"),
        meta: { title: "价格表", icon: "tree" },
      },
      {
        path: "/about/kmks",
        name: "aboutkmks",
        component: () => import("@/views/About/kmks"),
      },
    ]
  },

  {
    path: "/talk",
    component: Layout,
    children: [
      {
        path: "/talk",
        name: "talk",
        component: () => import("@/views/Tool/ChatVue.vue"),
        meta: { title: "文本对话", icon: "documentation" , keepAlive: true},
      },
    ],
  },

  {
    path: "/highlevel",
    component: Layout,
    children: [
      {
        path: "/highlevel",
        name: "highlevel",
        component: () => import("@/views/Tool/HighLevel/HighLevel.vue"),
        meta: { title: "高级定制", icon: "documentation" },
      }
    ],
  },
  {
    path: "/voice",
    component: Layout,
    children: [
      {
        path: "/voice",
        name: "VoiceMake",
        component: () => import("@/views/Tool/Voice/VoiceMake.vue"),
        meta: { title: "语音制作" ,keepAlive: true }
      },
    ],

  },
  {
    path: "/video",
    component: Layout,
    redirect: "/video/avatarmake",
    name: "Example",
    meta: { title: "Example", icon: "el-icon-s-help" },
    children: [
      {
        path: "/video/avatarmake",
        name: "avatarmake",
        component: () => import("@/views/Tool/Video/AvatarMake.vue"),
        meta: { title: "AI人物", icon: "table",keepAlive: true },
      },
      {
        path: "/video/customavatar",
        name: "customavatar",
        component: () => import("@/views/Tool/CustomMade/UsercustomAvatar.vue"),
        meta: { title: "Avatar制作", icon: "table" , keepAlive: true}
      },
      {
        path: "/video/aimake",
        name: "aimake",
        component: () => import("@/views/Tool/Video/AiModuleMake.vue"),
        meta: { title: "AI视频模块制作", icon: "tree" , keepAlive: true },
      },
      {
        path: "/video/translate",
        name: "translate",
        component: () => import("@/views/Tool/Video/VideoTranslate.vue"),
        meta: { title: "视频翻译", icon: "tree"  , keepAlive: true},
      }

    ]
  },

  {
    path: "/user",
    component: Layout,
    redirect: "/user/voice",
    name: "用户数据",
    meta: { title: "Example", icon: "el-icon-s-help" },
    children: [
      {
        path: "/user/voice",
        name: "uservoice",
        component: () => import("@/views/Tool/UserManage/UserVoice.vue"),
        meta: { title: "音频管理", icon: "table" },
      },
      {
        path: "/user/video",
        name: "uservideo",
        component: () => import("@/views/Tool/UserManage/UserVideo.vue"),
        meta: { title: "视频管理", icon: "table" },
      },
      {
        path: "/user/image",
        name: "userimage",
        component: () => import("@/views/Tool/UserManage/UserImage.vue"),
        meta: { title: "图片管理", icon: "table" },
      }
    ]
  }
];








const router = new Router ({
  mode: "history",
  routes: [...traditionalRoutes, ...ToolSystemRoutes],
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title // 设置页面标题
  }
  next()
})

export default router





