<template>
  <div class="layout-box">
    <div class="layout-top">
      <TopMenu />
    </div>
    <div class="layout-content">
      <div class="layout-menu-box">
        <SiderBarMenu />
      </div>
      <div class="layout-right">
        <keep-alive>
          <router-view
            v-if="$route.meta.keepAlive"
            :key="$route.path"
          ></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
      <div class="bottomFooter">
        <BlackbgFooter />
      </div>
    </div>
  </div>
</template>
<script>
import TopMenu from "../components/tools/TopMenuComp/TopMenu";
import SiderBarMenu from "../components/tools/SiderBarMenu";
import BlackbgFooter from "../components/BlackbgFooter";

export default {
  components: {
    TopMenu,
    SiderBarMenu,
    BlackbgFooter,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.layout-top {
  position: fixed;
  width: 100%;

  z-index: 1002;
}
.layout-content {
  position: relative;
  height: 100%;
  width: 100%;

  .layout-menu-box {
    -webkit-transition: width 0.28s;
    transition: width 0.28s;
    width: 200px !important;
    background-color: #304156;
    height: 100%;
    position: fixed;
    font-size: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    -webkit-box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
    box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
    & > div {
      width: 200px !important;
    }
  }

  .layout-right {
    min-height: 100%;
    margin-left: 200px;
    padding-top: 81px;
  }
}
</style>
