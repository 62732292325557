
import Vue from "vue";
import Vuex from "vuex"

Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        currentPlayingAudioId: "",
        importChooseLists:[],
        clickElement: {
            ElementId : '',
            time: Date.now()
        },
        currentLang:'zh'
    },
    mutations: {
        changePlayedId(state , newVal) {
            state.currentPlayingAudioId = newVal
        },
        getOptionAudioLists (state , newVal) {
          state.importChooseLists = newVal
        },
        setUpdateClickElement (state , newVal) {
            state.clickElement = {
                ElementId: newVal,
                time: Date.now()
            }
        },
        setUpdateLang(state , newVal) {
            state.currentLang = newVal
        }
    }

})

export default store