<template>
  <div class="icon-box">
    <div class="icon-list">
      <div class="iocn-item" v-for="(item, index) in iconList" :key="index">
        <img :src="item.icon" class="svgClass"  @click="LinkHandle(item)"/>
      </div>
    </div>
  </div>
</template>

<script>

import  { LinkTextDataUrl }  from '../utils/GlobalData'

export default {
  data() {
    return {
      iconList: LinkTextDataUrl,
    }
  },
  methods: {
    LinkHandle (item) {
      window.open(item.url)
    }
  }
};
</script>

<style scoped lang="scss">
.icon-list {
    display: flex;
    justify-content: end;
}
.svgClass {
  width: 22px;
  height: 22px;
  margin: 20px 0 20px 20px;
 
  cursor: pointer;
}
</style>
