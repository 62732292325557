
const zh= {
    system: "AI数字人AIGC全栈平台",
    theme: "AI 视频制作",
    firstIndex: "首页",
    topMenu:{
        menu01: "产品",
        menu02: "公司介绍",
        menu03: "价格"
    },
    login: "登录",
    register:"注册",
    logout:"退出",
    siderMenu: {
        Video2Video: "AI视频制作",
        AIWritting: "AI 文本",
        AIvoice: "AI 语音",
        AICharacter:"AI人物",
        VideoMake:"AI视频制作",
        Customized:"客户化定制",
        AIAvatar: "AI Avatar"
    },
    
    full: "全屏显示",
    account: "我的账户",
    invoice: "原始单据",
    reconciliation: "财务对账",
    record: "对账记录",
    report: "月结报表",
    setting: "系统设置",
   
    tips: "{name} 用户名和密码随便填 {cont}",
    administrator: "管理员",
    placeUser: "请输入用户名",
    palcePass: "请输入密码",
    palceCode: "请输入验证码",
    accounts: "账号",
    password: "密码",
    code: "验证码",
    footer: {
        CompanyProfile:"公司简介",
        introduction: "公司介绍",
        UserAgreement: "用户协议",
        Privacy:"隐私协议",
        Aboutus:"关于我们",
        ContactUs:"联系我们",
        CustomerService:"客服",
        TechnicalSupport:"技术支持"
    }
}
export default zh