<template>
  <div class="siderbar-box">
    <el-menu
      :default-active="$route.path"
      :default-openeds="openSubmenus"
      class="el-menu-vertical-demo ToolSider"
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
      background-color="#000"
      text-color="#fff"
      :active-text-color="activeTextColor"
      :collapse="isCollapse"
      style="height: 100vh"
      
    >
      <div class="logo" style="height: 120px"></div>
      <template v-for="(item, index) in btnList">
        <el-submenu :index="item.link" :key="index" v-if="item.children">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{ translatedMessage(item.titleKey) }}</span>
          </template>
          <template v-for="submenu in item.children">
            <el-menu-item :index="submenu.link" :key="submenu.link">{{
             
              translatedMessage(submenu.titleKey)
            }}</el-menu-item>
          </template>
        </el-submenu>

        <el-menu-item
          v-if="!item.children"
          :index="item.link"
          :key="item.link"
          :route="{ path: item.link }"
        >
          <i :id="item.elementId" class="el-icon-menu"></i>
          <span>{{ translatedMessage(item.titleKey) }}</span>
        </el-menu-item>
      </template>
      <template v-for="item in userMenuList">
        <el-submenu :index="item.link" :key="item.link" class="bottomSubmenu">
          <template slot="title">
            <i class="el-icon-user-solid"></i>
            <span slot="title">{{ item.title }}</span>
          </template>

          <el-menu-item
            :index="menu.link"
            :key="menu.link"
            v-for="menu in item.children"
            >{{menu.title}}</el-menu-item
          >
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  //   props: {
  //     isNewIndex: {
  //       type: String,
  //       default: "0",$route.path
  //     },
  //   },

  data() {
    return {
      activeTextColor: "#ffd04b",
      windowName: "myAppWindow", // 设置一个固定的窗口名称
      active: "",
      isCollapse: false,
      openSubmenus: ["/video", "/custommade"],
      userMenuList: [
        {
          title: "用户媒体库",
          elementId: "UserDataID",
          link: "/user",
          children: [
            {
              title: "音频数据",
              elementId: "userVoiceID",
              link: "/user/voice",
            },
            {
              title: "视频数据",
              elementId: "userVideoID",
              link: "/user/video",
            },
            {
              title: "图片数据",
              elementId: "userImageID",
              link: "/user/image",
            }
          ],
        },
      ],
      btnList: [
        {
          title: "AI视频制作",
          titleKey:"siderMenu.Video2Video",
          elementId: "AvatorID",
          link: "/video",
          children: [
            {
              title: "AI文本",
              titleKey:"siderMenu.AIWritting",
              elementId: "ChatID",
              link: "/talk",
            },
            {
              title: "AI语音",
              titleKey:"siderMenu.AIvoice",
              elementId: "VoiceID",
              link: "/voice",
            },
            {
              title: "AI人物",
              titleKey:"siderMenu.AICharacter",
              elementId: "AvatorID",
              link: "/video/avatarmake",
            },
            {
              title: "视频制作",
              titleKey:"siderMenu.VideoMake",
              elementId: "VideoID",
              link: "/video/aimake",
            },
            // {
            // title: "高级定制",
            // elementId: "AdvancedID",
            // link: "/highlevel",
            // },
          ],
        },
        {
          title: "客户化定制",
          titleKey:"siderMenu.Customized",
          elementId: "UserID",
          link: "/custommade",
          children: [
            {
              title: "Avatar定制",
              titleKey:"siderMenu.AIAvatar",
              elementId: "AvatarID",
              link: "/video/customavatar",
            },
          ],
        },
      ],
    };
  },

  mounted() {
    console.log("重新开始的mounted====,", this.$route.path);
    // 页面加载时恢复 openedWindows 状态
    // 监听 localStorage 的变化
    //  window.addEventListener("storage", this.handleStorageChange);
  },

  watch: {
    // isNewIndex(newVal, oldVal) {
    //   this.active = newVal;
    // },
  },
  methods: {
    // 动态翻译
    translatedMessage(title) {
      return this.$t(title);
    },
    handleSelect(url) {
      console.log(url);

      const type = {
        "/talk": "talkWindow",
        "/voice": "voiceWindow",
        "/video/avatarmake": "videoWindow",
        "/video/aimake": "videoWindow",
        "/highlevel": "videoWindow",
      };
      this.active = "";
      const baseUrl = window.location.origin; // 获取当前网站的基础URL
      const fullUrl = `${baseUrl}${url}`;

      // this.defaultActive = this.$route.path;

      this.$router.push(url)
      //window.open(fullUrl, this.windowName); // 实现一个模块一个窗口，已经打开的模块不新增窗口
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
.siderbar-box {
  position: fixed;
}

.ToolSider >>>  .el-menu-item {
    text-align: left;
    padding: 0 0 0 70px !important
}

.bottomSubmenu {
  position: absolute;
  bottom: 20px;
  width: 180px;
}
</style>
