import axios from 'axios'
// import Message from 'ant-design-vue/es/message'


axios.defaults.withCredentials = true
// create an axios instance
const request = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: '/',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
})

// request interceptor
request.interceptors.request.use(
  config => {
    let token = localStorage.getItem("sessionid") || "";
   // config.headers['USERUUID'] = generateUUID()
   let USERUUID = localStorage.getItem("USERUUID") || ""
    
    // let token = '';
    if (token) {
      config.headers['Authorization'] = 'sessionid='+token;
    }

    config.headers["USERUUID"] = USERUUID || ""
    // const cookie = document.cookie
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
request.interceptors.response.use(
  response => {
    const res = response
   
   
    // if the custom code is not 20000, it is judged as an error.
    return res
    // if (res.code !== '200') {
      //return Promise.reject(new Error(res.message || 'Error'))
      // return res
    // } else {
      // return res
    // }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default request
